import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
const Popup = (props) => {
  return (
    <>
      <div className="popupform">
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <h3>{props.title}</h3>
            <p className="desc text-start">{props.p1}</p>
            <p className="desc text-start">{props.p2}</p>
            <p className="desc text-center">{props.rera1}</p>
            <p className="desc text-center">{props.rera2}</p>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Popup;
