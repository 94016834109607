import { combineReducers } from "redux";
import { blogReducer } from "./blogReducers";
import { blogDetailReducers } from "./blogDetailReducers";
import { websiteLeadsReducers } from "./websiteLeadsReducers";
import { whatsappLeadsReducers } from "./whatsappLeadsReducers";
import { constructionUpdateReducer } from "./constructionUpdateReducers";
const reducers = combineReducers({
  // register reducer here

  blogPost: blogReducer,
  blogSingle: blogDetailReducers,
  leadSubmit: websiteLeadsReducers,
  whatsappSubmit: whatsappLeadsReducers,
  constructionUpdate: constructionUpdateReducer,

});

export default reducers;
