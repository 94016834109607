import { CLEAR_CONSTRUCT_ERRORS, FETCH_CONSTRUCT_FAIL, FETCH_CONSTRUCT_REQUEST, FETCH_CONSTRUCT_SUCCESS } from "../constants/constructionUpdateConstants ";

// Blog item reducer.
export const constructionUpdateReducer = (state = { constructionUpdate: [] }, action) => {
  switch (action.type) {
    case FETCH_CONSTRUCT_REQUEST:
      return {
        loading: true
      };

    case FETCH_CONSTRUCT_SUCCESS:
      return {
        loading: false,
        constructionUpdate: action.payload.data
      };

    case FETCH_CONSTRUCT_FAIL:
      return {
        error: action.payload.error.message
      };

    case CLEAR_CONSTRUCT_ERRORS:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
