import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import WhatsappForm from "./WhatsappForm";
const Popupwhatsappform = (props) => {
  return (
    <>
      <div className="popupform">
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <h3 className="text-white">{props.modalTitle}</h3>
            {/* <p className="text-center text-white">
            Do you want to receive important information and updates over whatsapp?
            </p> */}
            <div className="popupform">
              <WhatsappForm formsource="Whatsapp Form" />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Popupwhatsappform;
