import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "next/image";
import Popup from "./Popup";
import QueryString from "qs";
import useData from "@/hook/useData";
import Popupwhatsappform from "./Popupwhatsappform";
import { useRouter } from "next/router";
import Disclaimerpopup from "./Disclaimerpopup";
const Footer = () => {
  // const [modalShow, setModalShow] = useState(false);
  const [modalWhatsapp, setModalWhatsapp] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const handleCloseClick = () => {
    setShowChat(false);
  };
  const router = useRouter();
  
  const query = {
    populate: ["*"],
  };
  const queryString = QueryString.stringify(query, {
    encodeValuesOnly: true,
  });
  const endpoint = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}/api/typologies/?${queryString}`;
  const { footerlink, loading, error } = useData(endpoint);
  const footerlinkmenu = footerlink?.data;
  // console.log("footerlink", footerlinkmenu);
  // console.log('router.asPath',router)
  // useEffect(() => {
  //   if(router.asPath == '/' || router.route == '/[typology]'){
  //     const timer = setTimeout(() => {
  //       setModalWhatsapp(true);
  //     }, 4000);
  //     return () => clearTimeout(timer);
  //   }else{
  //     setModalWhatsapp(false);
  //   }
  
  // }, [router]);
  return (
    <>
      <footer className="blue_bg">
        <div className="footer-partner bg_white py-4">
          <Container>
            <ul className="marketing-list">
              <li>
                <span>Partners with</span>
                <span className="sbi-logo">
                  <Image
                    alt="SBI"
                    src="/images/partners_logo/sbibank_logo.png"
                    style={{
                      width: "100px",
                      height: "auto",
                      marginRight: "10px",
                    }}
                    width={100}
                    height={65}
                  />
                  <Image
                    alt="LICHFL"
                    src="/images/partners_logo/icici_bank.png"
                    width={200}
                    height={60}
                  />
                </span>
              </li>
              <li>
                <span>Pre-Certified Platinum-Rated by</span>
                <span className="rating-logo">
                  <a className="igbc-logo" href="/igbc">
                    <Image
                      alt="IGBC"
                      src="/images/partners_logo/igbc.png"
                      width={80}
                      height={80}
                    />
                  </a>
                </span>
              </li>
            </ul>
          </Container>
        </div>
        <Container fluid>
          <div className="footer-links p-4">
            <Row>
              <Col md={6} lg={3}>
                <div className="footer-box mt-4">
                  <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/New-Kolkata-1056735211131836"
                        target="_blank"
                        className="hover"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                        <span>facebook</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/new_kolkata"
                        target="_blank"
                        className="instagram hover"
                      >
                        <i className="fa-brands fa-twitter"></i>
                        <span>twitter</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/new_kolkata/"
                        target="_blank"
                        className="instagram hover"
                      >
                        <i className="fa-brands fa-instagram"></i>
                        <span>instagram</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@NewKolkatasangam"
                        target="_blank"
                        className="youtube hover"
                      >
                        <i className="fa-brands fa-youtube"></i>
                        <span>youtube</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.whatsapp.com/send/?phone=%2B918017012374&text=I+like+what+I+saw+on+newkolkata.in+and+want+to+know+more+about+Alcove+New+Kolkata."
                        target="_blank"
                        className="youtube hover"
                      >
                        <i className="fa-brands fa-whatsapp"></i>
                        <span>whatsapp</span>
                      </a>
                    </li>
                  </ul>
                  <div className="foo-rera text-white">
                    <p className="mb-0">
                      * NEW KOLKATA PRAYAG HIRA REGISTRATION NO:
                      HIRA/P/HOO/2018/000020 *
                    </p>
                    <p className="mb-0">
                      * NEW KOLKATA SANGAM HIRA REGISTRATION
                      NO:HIRA/P/HOO/2019/000635 *
                    </p>
                    <p>
                      To find out more about WBHIRA, Click here
                      https://hira.wb.gov.in/
                    </p>
                  </div>
                  <p style={{ fontSize: "14px" }} className="text-white mb-0">
                    * All images used are a representation of the architect's
                    imagination and not actual depictions
                  </p>
                </div>
              </Col>
              <Col md={3} lg={2}>
                <div className="mt-4">
                  <h4 className="loc_title text-white mb-3">Quick Links</h4>
                  <ul className=" links mb-0">
                    <li>
                      <Link href="/">Home</Link>
                    </li>
                    <li>
                      <Link href="/about-us">About us</Link>
                    </li>
                    <li>
                      <Link href="/sangam-residential-flat">Residences</Link>
                    </li>
                    <li>
                      <Link href="/sangam-specifications">Specifications</Link>
                    </li>
                    <li>
                      <Link href="/sangam-facilities">Facilities</Link>
                    </li>
                    <li>
                      <Link href="/location-nearby-facilities">Locations</Link>
                    </li>
                    <li>
                      <Link href="/contact-us">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3} lg={2}>
                <div className="mt-4">
                  <h4 className="loc_title text-white mb-3">Useful Links</h4>
                  <ul className=" links mb-0">
                    <li>
                      <Link href="/blogs">Blogs</Link>
                    </li>
                    <li>
                      <Link href="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link href="/igbc">IGBC</Link>
                    </li>
                    <li>
                      <Link href="/green-habitants">Green Habitants</Link>
                    </li>
                    <li>
                      <Link href="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link href="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={2}>
                <ul className=" links mt-4 mb-0">
                  <h4 className="loc_title text-white mb-3">Typology Links</h4>
                  {footerlinkmenu &&
                    footerlinkmenu.map((menuitem) => {
                      return (
                        <li key={menuitem?.id}>
                          <Link href={menuitem?.attributes?.slug}>
                            {menuitem?.attributes?.Title}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </Col>
              <Col md={6} lg={3}>
              <div className="whatsapp_box mt-4
                  ">
                    <h4 className="loc_title ms-0 text-white mb-2 text border-0">
                      Updates Over Whatsapp
                    </h4>
                    <p className="text-white">
                      Do you want to receive important information and updates
                      over whatsapp?
                    </p>
                    <div
                      className="round-btn"
                      onClick={() => setModalWhatsapp(true)}
                    >
                      Subscribe
                    </div>
                  </div>
              </Col>
            </Row>
            <div className="copyright text-center py-3">
              <div className="copyright text-white">
                Copyright © {new Date().getFullYear()} New Kolkata. All Rights
                Reserved&nbsp;|&nbsp;{" "}
                <Disclaimerpopup/>
                {/* <span
                  className="popup_btn text-white"
                  onClick={() => setModalShow(true)}
                >
                  View Disclaimer
                </span> */}
              </div>
            </div>
          </div>
        </Container>
      </footer>
      <div className="whatsfixed">
        <div className="whatsapp-wrap">
          <a
            href="https://wa.me/919330790936?text=Hello%2C%20I%20saw%20Alcove%20New%20Kolkata%20on%20newkolkata.in%20and%20would%20like%20to%20know%20more%20about%20the%20project.%20Please%20share%20the%20details%20with%20me."
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              width={65}
              height={65}
              src="/images/whatsapp.png"
              alt="whatsapp"
            />
          </a>
        </div>
        {showChat && (
          <div className="messageWrapperBox position-relative bg_white rounded text-center">
            <div className="text-end closebtn_wrp">
              <button className="closebtn" onClick={handleCloseClick}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <p className="border-bottom mb-1 pb-1">
              Hello There, we are available 24/7
            </p>
            <a
              className="brown-text"
              href="https://wa.me/919330790936?text=Hello%2C%20I%20saw%20Alcove%20New%20Kolkata%20on%20newkolkata.in%20and%20would%20like%20to%20know%20more%20about%20the%20project.%20Please%20share%20the%20details%20with%20me."
              target="_blank"
              rel="noopener noreferrer"
            >
              Chat Now
            </a>
          </div>
        )}
      </div>
     
      <Popupwhatsappform
        show={modalWhatsapp}
        onHide={() => setModalWhatsapp(false)}
        // modalTitle="Updates Over Whatsapp"
        className="whatsapp_popup"
      />
    </>
  );
};

export default Footer;
