import React, { useState } from 'react'
import Popup from './Popup';

function Disclaimerpopup() {
    const [modalShow, setModalShow] = useState(false);
    return (
       <>
        <span
            className="popup_btn text-white"
            onClick={() => setModalShow(true)}
        >
            Disclaimer
        </span>
        <Popup
        show={modalShow}
        onHide={() => setModalShow(false)}
        title="Disclaimer"
        p1="
        The contents of this website are compiled and intended to provide information to the best of our knowledge and while enough care is taken by New Kolkata to ensure that information on our website is up to date, accurate and correct, the users are advised to verify designs, plans, specifications, facilities, features, payment terms, etc. independently with New Kolkata and its sales team."
        p2="The information on this website shall be amended as per the stipulations/recommendations under the West Bengal Housing Industry Regulatory Act, 2017 (WBHIRA) and Rules made thereunder. As on date, the said information may not be fully in line with the same."
        rera1="NEW KOLKATA PRAYAG HIRA REGISTRATION NO: HIRA/P/HOO/2018/000020"
        rera2="NEW KOLKATA PRAYAG HIRA REGISTRATION NO: HIRA/P/HOO/2018/000020"
      />
       </>
        
    )
}

export default Disclaimerpopup
