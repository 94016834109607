import { FETCH_BLOGDETAIL_REQUEST, FETCH_BLOGDETAIL_SUCCESS, FETCH_BLOGDETAIL_FAIL, CLEAR_ERRORS } from "../constants/blogDetailConstants";

// About Us details reducer.
export const blogDetailReducers = (state = { blogSingle: [] }, action) => {
  switch (action.type) {
    case FETCH_BLOGDETAIL_REQUEST:
      return {
        loading: true
      };

    case FETCH_BLOGDETAIL_SUCCESS:
      return {
        loading: false,
        //   totalCount: action.payload.meta.pagination.total,
        //   resultsPerPage: action.payload.meta.pagination.pageSize,
        blogSingle: action.payload.data
      };

    case FETCH_BLOGDETAIL_FAIL:
      return {
        error: action.payload.error.message
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
