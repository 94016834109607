import axios from "axios";
import {
  LEADS_SUBMIT_REQUEST,
  LEADS_SUBMIT_SUCCESS,
  LEADS_SUBMIT_FAIL,
  CLEAR_ERRORS,
} from "../constants/websiteLeadsConstants";

export const getwebsiteLeads = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LEADS_SUBMIT_REQUEST,
    });

    const data = {
      data: {
        Name: formData.fname,
        Email: formData.email,
        Mobile: `${formData.mobile}`,
        FormSource: formData.source,
        Message: formData.message,
        Project: formData.project,
        Whatsapp_Update: formData.switch,
        utm_Source: formData.utm_source,
        utm_Subsource: formData.utm_subsource,
        utm_Campaign: formData.utm_campaign,
        utm_Terms: formData.utm_terms,
        utm_Placement: formData.utm_placement,
        utm_Device: formData.utm_device,
        lp_url: formData.url,
      },
    };

    // ============== Salesforce=====================

    // let sf_data = {
    //   data: {
    //     FName: "",
    //     LName: formData.fname,
    //     Phone: formData.mobile,
    //     City: "Kolkata",
    //     project: formData.project,
    //     Email: formData.email,
    //     Campaign: "",
    //     Source: formData.source,
    //     Sub_Source: "Leads From Website",
    //     UTM_Source__c: formData.utm_source,
    //     Medium: formData.utm_campaign,
    //     Content: "",
    //     Term: formData.utm_terms,
    //     lp_url: formData.url,
    //   },
    // };
    const final_jdata = JSON.stringify(data);

    const sendToSaleforce = async (final_jdata) => {
      var config = {
        method: "post",
        url: '/api/salesforce',
        headers: {
          "Content-Type": "application/json",
        },
        data: final_jdata,
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          dispatch({
            type: LEADS_SUBMIT_SUCCESS,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log("url");
    };
    // sendToSaleforce(final_jdata);

    // ============== Strapi backend=====================
    const config = {
      method: "post",
      url: `${process.env.NEXT_PUBLIC_BACKEND_API_URL}/api/website-leads`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        // after strapi now start saleforce api call
        sendToSaleforce(final_jdata);
      })
      .catch(function (error) {
        console.log(error);
      });

    // dispatch({
    //   type: LEADS_SUBMIT_SUCCESS,
    //   payload: response.data,
    // });

    // ============== Google sheet backend=====================
    // google sheet api
    // const formvalue = {
    //   entry_449648499: formData.fname,
    //   entry_1379833540: formData.email,
    //   entry_758332158: formData.mobile,
    //   entry_1850210371: formData.enqproject,
    //   entry_1900106466: formData.msg, //mag
    //   entry_1693361781: formData.source,
    //   entry_1830911442: formData.utm_source,
    //   entry_939490048: formData.utm_subsource,
    //   entry_729166844: formData.utm_campaign,
    //   entry_839903606: formData.utm_terms,
    //   entry_2003337495: formData.utm_placement,
    //   entry_399907321: formData.utm_device,
    //   entry_1966964226: "", //gclid
    // };
    // axios({
    //   method: "post",
    //   headers: {
    //     Accept: "application/json, text/plain, /",
    //     "Content-Type": "multipart/form-data",
    //   },
    //   url: "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfdStytX-z22HjagqQ2nVDmOg2DKK8SIQA0zTbDDwCWW_S-2A/formResponse",
    //   // withCredentials: false,
    //   data: formvalue,
    // }).then(function (response) {

    // });
    // google sheet api end
  } catch (error) {
    console.log("Error while submitting details: ");
    console.log(error);

    dispatch({
      type: LEADS_SUBMIT_FAIL,
      payload: error.response.data,
    });
  }
};

// Clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
