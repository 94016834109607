import axios from "axios";
import {
  LEADS_SUBMIT_REQUEST,
  LEADS_SUBMIT_SUCCESS,
  LEADS_SUBMIT_FAIL,
  CLEAR_ERRORS,
} from "../constants/whatsappLeadsConstants";

export const getwhatsappLeads = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: LEADS_SUBMIT_REQUEST,
    });

    const data = {
      data: {
        Name: formData.fname,
        // Email: formData.email,
        Mobile: `${formData.mobile}`,
        Project: "NEW KOLKATA - SANGAM",
        Whatsapp_Update: formData.switch,
        FormSource: formData.source,
        utm_Source: formData.utm_source,
        utm_Subsource: formData.utm_subsource,
        utm_Campaign: formData.utm_campaign,
        utm_Terms: formData.utm_terms,
        utm_Placement: formData.utm_placement,
        utm_Device: formData.utm_device,
        lp_url: formData.url,
      },
    };
    // ============== Salesforce=====================

    const final_jdata = JSON.stringify(data);

    const sendToSaleforce = async (final_jdata) => {
      var config = {
        method: "post",
        url: '/api/salesforce',
        headers: {
          "Content-Type": "application/json",
        },
        data: final_jdata,
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          dispatch({
            type: LEADS_SUBMIT_SUCCESS,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log("url");
    };
    // sendToSaleforce(final_jdata);

    // ============== Strapi backend=====================
    const config = {
      method: "post",
      url: `${process.env.NEXT_PUBLIC_BACKEND_API_URL}/api/whatsapp-update-leads`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        sendToSaleforce(final_jdata);
      })
      .catch(function (error) {
        console.log(error);
      });

    // dispatch({
    //   type: LEADS_SUBMIT_SUCCESS,
    //   payload: response.data,
    // });
  } catch (error) {
    console.log("Error while submitting details: ");
    console.log(error);

    dispatch({
      type: LEADS_SUBMIT_FAIL,
      payload: error.response.data,
    });
  }
};

// Clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
