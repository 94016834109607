import Image from "next/image";
import React, { useRef, useState } from "react";
import Logo from "../public/images/logo.png";
import Link from "next/link";
import PopupVideo from "./PopupVideo";
import Popupform from "./Popupform";

const Header = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const navbarCollapseRef = useRef(null);


  const [videourl, setVideourl] = useState("");
  const handleClick = (modalbol, videourl) => {
    setVideourl(videourl);
    setModalShow(modalbol);
  };

  const handleNavLinkClick = () => {
    if (navbarCollapseRef.current) {
      navbarCollapseRef.current.classList.remove("show");
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top">
        <div className="container">
          <Link className="navbar-brand" href="/">
            <Image src={Logo} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="d-flex justify-content-between w-100 d-md-none">
            <ul className="d-flex top-social list-unstyled mb-0 align-items-center justify-content-center">
              <li>
                <a
                  className="facebook_icon"
                  href="https://www.facebook.com/New-Kolkata-1056735211131836"
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook"></i>
                  <span>facebook</span>
                </a>
              </li>
              <li>
                <a
                  className="twitter_icon"
                  href="https://twitter.com/new_kolkata"
                  target="_blank"
                >
                  <i className="fa-brands fa-twitter"></i>
                  <span>twitter</span>
                </a>
              </li>
              <li>
                <a
                  className="insta_icon"
                  href="https://www.instagram.com/new_kolkata/"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram"></i>
                  <span>instagram</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@NewKolkatasangam"
                  target="_blank"
                >
                  <i className="fa-brands fa-youtube"></i>
                  <span>youtube</span>
                </a>
              </li>
              <li className="whats__app">
                <a
                  href="https://api.whatsapp.com/send/?phone=%2B918017012374&amp;text=I+like+what+I+saw+on+newkolkata.in+and+want+to+know+more+about+Alcove+New+Kolkata."
                  target="_blank"
                >
                  <i aria-hidden="true" className="fa-brands fa-whatsapp"></i>
                  <span>whatsapp</span>
                </a>
              </li>
            </ul>
            <div>
              <a
                className="round-btn"
                href="#"
                onClick={() => setModalShow1(true)}
              >
                Submit Your Query
              </a>
            </div>
          </div>
          <div
            className="collapse navbar-collapse flex-column"
            id="navbarSupportedContent"
            ref={navbarCollapseRef}
          >
            <ul className="d-md-flex top-social list-unstyled ms-auto mb-0 align-items-center d-none">
              <li>
                <a
                  className="facebook_icon"
                  href="https://www.facebook.com/New-Kolkata-1056735211131836"
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  className="twitter_icon"
                  href="https://twitter.com/new_kolkata"
                  target="_blank"
                >
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  className="insta_icon"
                  href="https://www.instagram.com/new_kolkata/"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@NewKolkatasangam"
                  target="_blank"
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
              <li className="whats__app">
                <a
                  href="https://wa.me/919330790936?text=Hello%2C%20I%20saw%20Alcove%20New%20Kolkata%20on%20newkolkata.in%20and%20would%20like%20to%20know%20more%20about%20the%20project.%20Please%20share%20the%20details%20with%20me."
                  target="_blank"
                >
                  <i aria-hidden="true" className="fa-brands fa-whatsapp"></i>
                </a>
              </li>
              <li className="header-social-list">
                <a className="phone_icon" href="tel:81018-81018">
                  <i className="fa fa-phone"></i> &nbsp;
                  <span>81018-81018</span>
                </a>
              </li>
              <li>
                <a
                  className="round-btn"
                  href="#"
                  onClick={() => setModalShow1(true)}
                >
                  Submit Your Query
                </a>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" href="/" onClick={handleNavLinkClick}>
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Residences
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      href="/sangam-residential-flat"
                      onClick={handleNavLinkClick}
                    >
                      Sangam
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" href="/residential-flat"
                    onClick={handleNavLinkClick}
                    >
                      Prayag
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Facilities
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" href="/sangam-facilities" onClick={handleNavLinkClick}>
                      Sangam
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      href="/facilities-amenities"
                      onClick={handleNavLinkClick}
                    >
                      Prayag
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Specifications
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      href="/sangam-specifications"
                      onClick={handleNavLinkClick}
                    >
                      Sangam
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" href="/specifications"  onClick={handleNavLinkClick}>
                      Prayag
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Locations
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      href="#"
                      onClick={() =>{
                        handleClick(
                          true,
                          "https://www.youtube.com/embed/8O3K2AJLelY"
                        );
                        
                      }
                      }
                    >
                      From Dunlop
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleClick(
                          true,
                          "https://www.youtube.com/embed/lFeqV4Qj6b8"
                        )
                      }
                    >
                      From Rishra Station
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleClick(
                          true,
                          "https://www.youtube.com/embed/fhAQf9iS2sc"
                        )
                      }
                    >
                      From Serampore Station
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="/gallery" onClick={handleNavLinkClick}>
                  Gallery
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="/riverside-apartments" onClick={handleNavLinkClick}>
                  Riverside Apartments
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="/contact-us" onClick={handleNavLinkClick}>
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* <section class="marquee_sec">
        <p class="marquee text-styling mb-0"><strong>Our offices will be closed from October 21st to October 24th on the occasion of Durga Puja. The offices will reopen on October 25th.</strong></p>
      </section> */}
      <PopupVideo
        show={modalShow}
        onHide={() => setModalShow(false)}
        video_link={videourl}
      />
      <Popupform
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        modalTitle="Enquire Now"
      />
    </>
  );
};

export default Header;
