import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getwhatsappLeads,
} from "@/redux/actions/whatsappLeadsAction";
import Disclaimerpopup from "./Disclaimerpopup";

const WhatsappForm = (props) => {
  const [source, setSource] = useState("");
  const [checked, setChecked] = useState(true);
  const handleClick = (sourceValue) => {
    setSource(sourceValue);
  };
  let whatsappcont;
  if (checked == true) {
    whatsappcont = 'Yes';
  } else {
    whatsappcont = 'No';
  }
  const router = useRouter();

  const dispatch = useDispatch();
  const { success, error, loading } = useSelector((state) => state.whatsappSubmit);
  useEffect(() => {
    if (success) {
      // toast.success("Thank you for your reference.");
      // router.push("/thank-you");
      window.location.href = "/thank-you";
    }
    if (error) {
      dispatch(getwebsiteLeads(clearErrors));
    }
  }, [dispatch, success, error]);

  const { query } = router;

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const formik = useFormik({
    initialValues: {
      fname: "",
      mobile: "",
      // email: "",
      switch: false,
      utm_source: query.utm_source,
      utm_subsource: query.utm_subsource,
      utm_campaign: query.utm_campaign,
      utm_terms: query.utm_terms,
      utm_placement: query.utm_placement,
      utm_device: query.utm_device,
      source: "",
      url: "",
    },

    validationSchema: Yup.object({
      fname: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Please Enter Your Name"),

      // email: Yup.string()
      //   .email("Invalid email address")
      //   .required("Please Enter Your Email Id"),

      mobile: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .max(10, "Must be 10 characters or less")
        .required("Please Enter Mobile Number"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      let finalValues = {};
      finalValues.fname = values.fname;
      // finalValues.email = values.email;
      finalValues.mobile = values.mobile;
      finalValues.source = "Website New Kolkata";
      finalValues.switch = whatsappcont;
      finalValues.utm_source = query.utm_source;
      finalValues.utm_subsource = query.utm_subsource;
      finalValues.utm_campaign = query.utm_campaign;
      finalValues.utm_terms = query.utm_terms;
      finalValues.utm_placement = query.utm_placement;
      finalValues.utm_device = query.utm_device;
      finalValues.url = router.asPath;

      console.log("finalValues", finalValues);

      dispatch(getwhatsappLeads(finalValues));

      setSubmitting(false);

      resetForm();
    },
  });
  return (
    <>
      <Form
        onSubmit={formik.handleSubmit}
        className="needs-validation contact-form"
        noValidate
      >
        <Row className="gx-lg-5 gx-auto justify-content-center">
          <Form.Group
            as={Col}
            xs={12}
            md={2}
            className="mb-3 form-group mb-lg-0"
            controlId="fname"
          >
            <Form.Control
              type="text"
              placeholder="Name"
              name="fname"
              onChange={formik.handleChange}
              value={formik.values.fname}
            />
            {formik.touched.fname && formik.errors.fname && (
              <span className="error">{formik.errors.fname}</span>
            )}
          </Form.Group>
          {/* <Form.Group
            as={Col}
            xs={12}
            md={2}
            className="mb-3 form-group mb-lg-0"
            controlId="email"
          >
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <span className="error">{formik.errors.email}</span>
            )}
          </Form.Group> */}
          <Form.Group
            as={Col}
            xs={12}
            md={2}
            className="mb-3 form-group mb-lg-0"
            controlId="phone"
          >
            <Form.Control
              name="mobile"
              type="number"
              placeholder="Mobile"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobile}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <span className="error">{formik.errors.mobile}</span>
            )}
          </Form.Group>

          <Form.Group
            as={Col}
            xs={12}
            md={2}
            className="mb-0 mb-lg-0 checkwrp h-auto"
          >
            <Form.Check
            reverse
              type="switch"
              label="Get updates on whatsapp"
              name="switch"
              id="switch"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            {/* {formik.touched.mobile && formik.errors.whatsappconsent && (
              <span className="error">{formik.errors.whatsappconsent}</span>
            )} */}
           
          </Form.Group>
          <div  className="termCondition mb-3 h-auto w-100 text-center"> By clicking, you agree to our <a  target="_blank" href="/privacy-policy/"> Privacy policy</a> &amp; <Disclaimerpopup/></div>
          <Form.Group
            as={Col}
            xs={12}
            md={2}
            className="mb-3 form-group mb-lg-0 text-end mt-3 mt-md-0 submit-btn"
          >
            <Button type="submit" onClick={() => handleClick(props.formsource)}>
              Submit
            </Button>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
};

export default WhatsappForm;
