import React, { useState } from "react";
import ContactForm from "./ContactForm";
import Modal from "react-bootstrap/Modal";
const Popupform = (props) => {
  return (
    <>
      <div className="popupform">
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <h3>{props.modalTitle}</h3>
            <p className="text-center">
              Please Enter Your Details To Know More {props.projectTitle}
            </p>
            <div className="popupform">
              <ContactForm formsource="Enquiry form" />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Popupform;
