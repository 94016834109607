import { FETCH_BLOG_REQUEST, FETCH_BLOG_SUCCESS, FETCH_BLOG_FAIL, CLEAR_ERRORS } from "../constants/blogConstants";

// Blog item reducer.
export const blogReducer = (state = { blogPost: [] }, action) => {
  switch (action.type) {
    case FETCH_BLOG_REQUEST:
      return {
        loading: true
      };

    case FETCH_BLOG_SUCCESS:
      return {
        loading: false,
          totalCount: action.payload.meta.pagination.total,
          resultsPerPage: action.payload.meta.pagination.pageSize,
        blogPost: action.payload.data
      };

    case FETCH_BLOG_FAIL:
      return {
        error: action.payload.error.message
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
