import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
const Popupvideo = (props) => {
  return (
    <>
      <div className="popupform">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
          <Modal.Body>
            <iframe
              src={`${props.video_link}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
              height={445}
              width={1920}
              className="w-100"
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Popupvideo;
